<template>

  <div style="background-color: #003262;">

     <div class="topBanner">
      <div style="position: absolute;z-index:2;width: 100%;height: 100%">
        <img src="../../assets/newNavAllBg.png" style="width: 100%;height: 100%;object-fit: fill"/>
      </div>
      <!-- 756 × 136 -->
      <div style="margin-left: 150px;position: absolute;z-index:3;width: 189px;height: 34px">
        <img src="../../assets/newNavBg.png" style="width: 100%;height: 100%;object-fit: fill"/>
      </div>

      <div class="logoutStyle" @click="changeView()">
        Logout
      </div>
      
    </div>

    <div class="topBanner1">

         <div class="countryStyle">
          {{student_school}}
        </div>

        <div class="nameStyle">
          {{student_name}}
        </div>

    </div>


    <div class="ready-root">

        <div class="lisbgimgview">
          <img src="../../assets/newListBg.png" style="width: 100%;height: 100%;object-fit: fill;z-index:2;"/>
        </div>


      <div style="text-align: left;z-index:3;">
        <div class="ready-text">Please do equipment check before entering the test:</div>
        <div style="margin-left: 150px;color:#003262;margin-top:10px;font-size: 13px;">The authorization on this preparation page is for equipment check only. You need re-authorize your camera and screen sharing after you enter the test page.</div>
        <div class="openStyle">
          <div class="checkStyle">
            <div :class= "device_camera_status ? 'checkDetailStyle1' : 'checkDetailStyle'">
              <img v-if="device_camera_status" src="../../assets/device_success.png" class="ready-img">
              <img v-if="!device_camera_status" src="../../assets/device_error.png" class="ready-img">
              <div class="ready-text1" style="margin-left: 15px">Camera Sharing Check</div>
              <div v-if="!device_camera_status" class="ready-restart" @click="checkDevice">Resume</div>
            </div>
            
          </div>
          <div class="checkStyle" >
            <div :class= "device_screen_status ? 'checkDetailStyle1' : 'checkDetailStyle'">
              <img v-if="device_screen_status" src="../../assets/device_success.png" class="ready-img">
              <img v-if="!device_screen_status" src="../../assets/device_error.png" class="ready-img">
              <div class="ready-text1" style="margin-left: 15px">[Full Screen Sharing*] Check</div>
              <div v-if="!device_screen_status" class="ready-restart" @click="checkDevice">Resume</div>
            </div>

            
          </div>
          <div class="checkStyle">

            <div  :class= "idCheckSuccess ? 'checkDetailStyle1' : 'checkDetailStyle'">
              <img v-if="idCheckSuccess" src="../../assets/device_success.png" class="ready-img">
              <img v-if="!idCheckSuccess" src="../../assets/device_error.png" class="ready-img">
              <div class="ready-text1" style="margin-left: 15px">[ID Document**] Photo Upload</div>
              <div style="display: flex;flex-direction: row;text-align: left;margin-top: 40px;align-items: center">
                <div v-if="pic_url!=''"  class="ready-restart—1" style="margin-left: 20px" @click="showIdPicDialog=true">ShowID</div>
                <div class="ready-restart—1" style="margin-left: 10px" @click="doShowIdDialog">Upload</div>
              </div>
            </div>

            
          </div>

        </div>
        
        <div style="position: relative;z-index:3;margin-left: 150px;color:#8F8F8F;margin-top:10px;line-height:200%;font-size: 10px;font-family: Montserrat-SemiBold;">* You must share the [full screen], but not only a single window or browser tab.
          <br>** ID Document refers to any official document that proves a test taker's identity (e.g. National ID card, driver license, School ID card, National Security Card, Passport, etc.).
          <br>When photographing, make sure the photo and name side of your ID document is clearly visible in front of the camera. To avoid losing focus, do not put it too close to the camera .
          <br>The photograph is only for use of test proctoring and it will be deleted from the server after 5 business days.
        </div>
        <div style="position: relative;z-index:3;margin-left: 150px;color:#8F8F8F;margin-top:10px;line-height:200%;font-size: 10px;font-family: Montserrat-SemiBold;">Attention: you must use a desktop computer (Windows or Mac). Mobile phones and tablets are not supported. You also must use Goolge Browser Chrome and authorize your computer and browser to share your camera and full screen.<br>Do not know how to authorize your camera and screen sharing? Please view<a
              href="https://compete.berkeley-fbl.org/equipment_setting_guide.pdf"
              target="_blank"><span
              style="background-color:#00309F;color:white;margin-left:5px;padding:4px;border-radius:4px;font-family: Montserrat-SemiBold;">Equipment Setting Guide</span></a>
        </div>
        <div style="position: relative;z-index:3;margin-left: 150px;color:#8F8F8F;margin-top:10px;font-size: 10px;font-family: Montserrat-SemiBold;">All screen cuttings will be recorded and reviewed by proctors.</div>
      </div>
      <div class="ready-go-exam" @click="goExam">
        Enter Test
      </div>
      
    </div>

     <div class="bottomView">
         <div v-show="!isChrome" class="navigator-tip-1">ALERT: you are NOT using Google Chrome to visit this site, which might cause unexpected misfunctions or bugs. The developer of this system requires visiting via Chrome and will not be responsible for any errors on non-Chrome platforms.</div>
    </div>


    <canvas id="cameraCanvas" :width="cameraOptions.video.width" :height="cameraOptions.video.height"
              style="display: none;"></canvas>
      <canvas id="screenCanvas" :width="screenOptions.video.width" :height="screenOptions.video.height"
              style="display: none;"></canvas>

      <el-dialog :visible.sync="showUploadIdDialog" width="70%" title="Photographing" :close-on-click-modal="false"
                :close-on-press-escape="false">
        <el-form>
          <div class="camera-box">
            <div style="display: flex;flex-direction: row">
              <div>
                <div style="margin-bottom: 10px">Preview</div>
                <video id="id-video" :width="idVideoWidth" :height="idVideoHeight"></video>
              </div>
              <div style="margin-left: 10px">
                <div style="margin-bottom: 10px">Photo</div>
                <canvas id="id-canvas" :width="idVideoWidth" :height="idVideoHeight"></canvas>
              </div>
            </div>
            <p class="camera-p">{{ 'Note: please put your ID document in the middle and keep the camera focused with photo and name clearly visible. Press "take" button to confirm'}}</p>
            <div style="margin-top: 15px;display: flex;flex-direction: row">
              <el-button type="primary" @click="setImage">{{ imgSrc ? 'Retake' : 'Take' }}</el-button>
              <el-button type="primary" @click="setFileUpload" v-if="imgSrc">Submit</el-button>
            </div>
          </div>
        </el-form>
      </el-dialog>


    <el-dialog :visible.sync="showIdPicDialog" width="40%">
      <div style="text-align: center">
        <el-image :src="pic_url"></el-image>
      </div>
    </el-dialog>
  </div>

</template>

<script>

import {checkIsUploadTest, uploadPic, uploadStudentIdCard, getStudentIdCard,getTeamMembers} from '../../api/index'
import {getProjectCode, getUserId,getUserName, getAccountName,logout,getBrowserType} from "../../utils/store";
import '../../assets/common/font.css'


export default {
  name: "ExamReady",
  data() {
    return {
      imgSrc: '',
      showUploadIdDialog: false,
      cameraOptions: {
        audio: false,
        video: {width: 500, height: 500}
      },
      screenOptions: {
        audio: false,
        video: {width: 1280, height: 720}
      },
      timer: '',
      status: false,
      have_exam: false,
      device_camera_status: true,
      device_screen_status: true,
      template_id: this.$route.query.template_id,
      start_time: this.$route.query.start_time,
      event_type: this.$route.query.event_type,
      cameraVideo: null, // 摄像头视频容器
      screenVideo: null, // 屏幕视频容器
      cameraStream: null, // 摄像头视频流
      screenStream: null, // 屏幕视频流

      idCanvas: undefined,
      idVideo: undefined,
      idContext: undefined,
      idVideoWidth: 400,
      idVideoHeight: 300,
      idCheckSuccess: false,
      idStream: null,
      idVideoStream: null,
      student_name: '',
      student_school: '',
      pic_url:'',
      showIdPicDialog:false,
      isChrome:false
    }
  },
  mounted() {
    // this.student_name = getUserName()
    // this.student_school = getAccountName()
    let browser = getBrowserType()
    if(browser==='CHROME'){
        this.isChrome = true
    }
    this.checkStudentExam()
    this.startCheckOnline()
    this.checkDevice()
    this.checkStudentId()
    this.getTeamMembers()
  },
  beforeDestroy() {
    console.log('---beforeDestroy---')
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    this.clearAllResource()
  },
  methods: {
    getTeamMembers(){
      getTeamMembers(getProjectCode(),getUserId()).then((res) => {
        var result = res.data.data

        console.log(1111,result)

        this.student_name = result.team_names.join(' ')
        this.student_school = result.country
        
      })
    },
    checkStudentId() {
      getStudentIdCard(getUserId(), this.template_id, getProjectCode()).then((res) => {
        var result = res.data.data
        if (result.pic_url) {
          this.idCheckSuccess = true
          this.pic_url = result.pic_url
        }
      })
    },
    changeView() {
      
        this.$confirm('Are you sure you want to exit?', 'Tips', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        }).then(() => {
          this.logout()
        })
      

    },
    logout() {
      logout()
      location.reload()
    },
    doShowIdDialog() {
      this.imgSrc = ''
      this.showUploadIdDialog = true
      this.$nextTick(() => {
        this.getCompetence()
      })
    },
    setImage() {
      var _this = this
      // 点击，canvas画图
      _this.idContext.drawImage(_this.idVideo, 0, 0, _this.idVideoWidth, _this.idVideoHeight)
      // 获取图片base64链接
      var image = this.idCanvas.toDataURL('image/png')
      _this.imgSrc = image;
    },
    setFileUpload() {
      let param = new FormData()
      var time = new Date()
      var file_name = 'id_pic_' + time.getTime()
      var file = this.dataURLtoFile(this.imgSrc, file_name)
      param.append('function', 'uploadPic')
      param.append('file', file)
      uploadPic(param).then(res => {
        console.log(res)
        var result = res.data.data
        if (result) {
          this.doSavePic(result)
        } else {
          this.clearIdStream()
          this.$message.success("ID Photo Upload failed. Please try again!")
        }
      })
    },
    doSavePic(pic_url) {
      uploadStudentIdCard(getUserId(), this.template_id, getProjectCode(), pic_url).then((res) => {
        this.idCheckSuccess = true
        this.pic_url = pic_url
        this.$message.success("ID Photo Upload Success")
        this.showUploadIdDialog = false
        this.clearIdStream()
      })
    },
    dataURLtoFile(dataUrl, fileName) {
      var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, {type: mime});
    },
    getCompetence() {
      // 初始化的时候直接让为空
      this.imgSrc = '';
      var _this = this;
      this.idCanvas = document.getElementById('id-canvas')
      this.idContext = this.idCanvas.getContext('2d')
      this.idVideo = document.getElementById('id-video')
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }
      var constraints = {
        audio: false,
        video: {width: this.idVideoWidth, height: this.idVideoHeight, transform: 'scaleX(-1)'}
      }
      navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
        _this.idStream = stream
        if ('srcObject' in _this.idVideo) {
          _this.idVideo.srcObject = stream
          _this.idVideoStream = stream
        } else {
          _this.idVideo.src = window.URL.createObjectURL(stream)
          _this.idVideoStream = stream
        }
        _this.idVideo.onloadedmetadata = function (e) {
          _this.idVideo.play()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    goExam() {
      

      if (this.have_exam) {
        this.$message.error('You have already completed the test.')
        return
      }

      if (!this.status) {
        this.$message.error('You can not enter the test as your equipment is not set up correctly.')
        return
      }

      if(!this.idCheckSuccess){
        this.$message.error('Please upload your ID document photo first.')
        return
      }

      var currentTime = new Date().getTime()

      if (this.event_type == 'Formal') {
        if (this.start_time > currentTime) {
          this.$message({
            message: 'The test has not started. Please wait for a while.',
            type: 'warning'
          })
          return
        }

        if (this.start_time + 7200000 < currentTime) {
          this.$message({
            message: 'The test entry time is over. You cannot enter the test room now.',
            type: 'warning'
          })
          return
        }
      }


      this.$router.replace({
        path: '/exam',
        query: {
          template_id: this.template_id
        }
      })
    },
    async checkDevice() {
      if (this.status) {
        return
      }
      this.clearAllResource()
      // 判断浏览器是否有权限
      if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        this.$message.error('Your browser does not support camera sharing')
        return console.log('浏览器不支持访问摄像头!');
      }
      if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
        this.$message.error('Your browser does not support screen sharing')
        return console.log('浏览器不支持屏幕录制!');
      }
      this.cameraVideo = document.createElement('video');
      this.screenVideo = document.createElement('video');
      // 视频流
      const loading = this.$loading({
        lock: true,
        text: 'Getting camera authorization..',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let stream = await navigator.mediaDevices.getUserMedia(this.cameraOptions).catch((err) => {
        this.$message.error(`摄像头获取失败 error:${err}`)
        this.device_camera_status = false
        this.clearAllResource()
        return
      }).finally(() => {
        loading.close()
      });
      this.cameraStream = stream
      this.cameraVideo.srcObject = stream;
      this.cameraVideo.play();
      loading.close()
      // 屏幕流
      const loading1 = this.$loading({
        lock: true,
        text: 'Getting screen authorization...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let stream1 = await navigator.mediaDevices.getDisplayMedia(this.screenOptions).catch((err) => {
        this.$message.error(`Screen authorization failed error:${err}`)
        this.device_screen_status = false
        this.clearAllResource()
        return
      }).finally(() => {
        loading1.close()
      });
      this.device_camera_status = true
      if (stream1 && stream1.getVideoTracks()[0].getSettings().displaySurface !== 'monitor') {
        this.$alert('Please choose to share the full screen', 'Screen authorization failed', {confirmButtonText: 'OK', type: 'error'})
        this.device_screen_status = false
        this.clearAllResource()
        return
      }
      this.device_screen_status = true
      this.screenStream = stream1
      this.screenVideo.srcObject = stream1;
      this.screenVideo.play();
      this.status = true
      loading.close()
    },
    startCheckOnline() {
      this.timer = setInterval(() => {
        if (!this.cameraStream || !this.cameraStream.active) {
          this.device_camera_status = false
          if (this.status) {
            this.clearAllResource()
          }
          this.status = false
        }
        if (!this.screenStream || !this.screenStream.active) {
          this.device_screen_status = false
          if (this.status) {
            this.clearAllResource()
          }
          this.status = false
        }
      }, 1000)
    },
    clearIdStream() {
      if (this.idStream && this.idStream.getTracks) {
        this.idStream.getTracks().forEach(track => track.stop())
        this.idStream = null
      }
      this.idVideoStream = null
    },
    clearAllResource() {
      this.clearIdStream()
      if (this.cameraStream && this.cameraStream.getTracks) {
        this.cameraStream.getTracks().forEach(track => track.stop())
      }
      if (this.screenStream && this.screenStream.getTracks) {
        this.screenStream.getTracks().forEach(track => track.stop())
      }
      this.cameraVideo = null
      this.screenVideo = null
      this.cameraStream = null
      this.screenStream = null
      this.status = false
    },
    checkStudentExam() {
      checkIsUploadTest(this.template_id, getUserId(),getProjectCode()).then((res) => {
        var result = res.data
        this.have_exam = result

      })
    }
  },
}

</script>

<style scoped>

.ready-go-exam {
  position: relative;
  z-index:3;
  margin-left: 150px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  width: 130px;
  height: 30px;
  background-color: #00309F;
  background: #00309F;
  opacity: 1;
  border-radius: 15;
  font-size: 20px;
  font-family: Montserrat-SemiBold;
  font-weight: bold;
  line-height: 30px;
  color: white;
}

.ready-img {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 20px;
  height: 20px;
  float: left;
}

.ready-restart {
  cursor: pointer;
  /* border-radius: 0.8vw; */
  background-color: #00309F;
  color: white;
  font-size: 1vw;
  font-family: Montserrat-SemiBold;
  font-weight: bold;
  margin-top: 40px;
  /* margin-bottom: px; */
  width: 50px;
  padding: 0.108vw 0.5vw;
  margin-left: 10px;
}

.ready-restart—1 {
  cursor: pointer;
  /* border-radius: 0.8vw; */
  background-color: #00309F;
  color: white;
  font-size: 1vw;
  font-family: Montserrat-SemiBold;
  font-weight: bold;
  /* margin-bottom: px; */
  width: 50px;
  padding: 0.108vw 0.5vw;
}

.ready-text {
  margin-top: 30px;
  margin-left: 150px;
  font-size: 20px;
  color: #00309F;
  font-family: Montserrat-SemiBold;
  font-weight: bold
}

.ready-root {
  position: relative;
  z-index: 1;
  /* margin-top: 10px; */
  /* margin-bottom: 50px; */
  width: 100vw;
  /* padding-top: 50px; */
  /* padding-bottom: 50px; */
  height: calc(100vh - 150px);
  overflow-y: scroll;
  overflow-x: hidden;
  /* background-image: url("../../assets/exam_ready_bg.png"); */
  background-color: white;
  /* background-size: cover; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
}

.logoutStyle{
  right: 200px;
  position: absolute;
  z-index:3;
  width: 60px;
  color: white;
  height: 15px;
  border:1px solid white;
  text-align: center;
  font-size: 10px;
  line-height: 15px;
  font-family: Montserrat-SemiBold;
}


.topBanner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
}
.topBanner1 {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #D9661F;
  background-size: cover;
}
.countryStyle{
  position: absolute;
  margin-top: -20px;
  margin-left: 150px;
  font-size: 20px;
  color: white;
  height: 25px;
  font-family: Montserrat-SemiBold;
}

.nameStyle{
  position: absolute;
  margin-top: 25px;
  margin-left: 150px;
  font-size: 13px;
  color: white;
  height: 25px;
  font-family: Montserrat-SemiBold;
}
.openStyle{
  margin-top: 20px;
  background-color: white;
  margin-left: 150px;
  width: calc(100vw - 300px);
  height: 80px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  -moz-user-select:none;
  user-select: none;
}
.ready-text1{
  /* margin-left: 150px; */
  margin-top: 10px;
  font-size: 15px;
  color: #00309F;
  font-family: Montserrat-SemiBold;
  font-weight: bold;
  float: left;
}
.bottomView{
  position: relative;
  /* z-index: 1; */
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #00309F;
  background-size: cover;
}
.lisbgimgview{
  position: absolute;
  z-index:0;
  width: 265.25px;
  height: 220.5px;
  bottom: 50px;
}
.checkStyle{
  margin-top: 0px;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  /* flex-direction: row; */
  align-items: left;
  text-align: left
}
.checkDetailStyle{
  /* margin-left: 10px; */
  margin-right: 10px;
  height: 80px;
  background-color: #F9F9F9;
  border:2px solid red;
  
}
.checkDetailStyle1{
  /* margin-left: 10px; */
  margin-right: 10px;
  height: 80px;
  background-color: #F9F9F9;
  border:2px solid white;
  
}
.navigator-tip-1{
    position: absolute;
    height: 54px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    bottom: 0;
    color: white;
    font-size: 14px;
    text-align: right;
    width: 100%;
}
</style>